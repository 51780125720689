$spacers: (
	0: 0,
	1: 1rem * 0.25,
	2: 1rem * 0.5,
	3: 1rem,
	4: 1rem * 1.5,
	5: 1rem * 3,
	6: 1rem * 3.5,
	7: 1rem * 4,
	8: 1rem * 5,
	9: 1rem * 6,
	10: 1rem * 7.5,
	11: 1rem * 10,
);

$font-sizes: (
	1: 1rem * 2.5,
	2: 1rem * 2,
	3: 1rem * 1.75,
	4: 1rem * 1.5,
	5: 1rem * 1.25,
	6: 1rem,
	7: 1rem * 0.875,
	8: 1rem * 0.75,
	9: 1rem * 0.625,
);

$theme-colors: (
	'white': #ffffff,
	'gray-lightest': #fafafa,
	'gray-lighter': #eeeeee,
	'gray-light': #9e9e9e,
	'gray': #616161,
	'gray-dark': #424242,
	'black': #000000,
	'blue-lightest': #e3f2fd,
	'blue-lighter': #2196f3,
	'blue-light': #1565c0,
	'blue': #0d47a1,
	'orange': #ff9800,
	'green-lightest': #e8f5e9,
	'green': #4caf50,
	'red-lightest': #ffebee,
	'red': #d50000,
);

$box-shadow-lg: 0 16px 48px 0 rgba(map-get($theme-colors, 'black'), 0.08);
$border-radius: 8px;
$border-radius-lg: 16px;
$enable-negative-margins: true;

@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
	font-family: 'Roboto', sans-serif;
}

* {
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}

.fw-medium {
	font-weight: 500;
}

.swiper-pagination {
	position: static;
	line-height: 0;

	&.swiper-pagination-white {
		.swiper-pagination-bullet {
			background: map-get($theme-colors, 'white');
		}
	}

	.swiper-pagination-bullet {
		background: map-get($theme-colors, 'gray');
		opacity: 0.5;
		transition: all 0.25s;

		&.swiper-pagination-bullet-active {
			width: 16px;
			border-radius: 8px;
			opacity: 1;
		}
	}
}

header {
	&.scrolled {
		@extend .shadow-lg;
		transition: all 0.25s;

		.navbar-logo {
			img {
				height: 48px;
			}
		}
	}

	.navbar-logo {
		img {
			height: 80px;
			transition: all 0.25s;

			@include media-breakpoint-down(xxl) {
				height: 48px;
			}
		}
	}

	.navbar-toggler {
		width: 30px;
		height: 30px;
		padding: 0;
		border: none;
		transform: scale(0.8);
		transition: 0.5s ease-in-out;

		&:not(.collapsed) {
			span {
				&:nth-child(1) {
					transform: rotate(45deg);
					top: 2;
					left: 4px;
				}

				&:nth-child(2) {
					width: 0%;
					opacity: 0;
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
					top: 24px;
					left: 4px;
				}
			}
		}

		span {
			display: block;
			position: absolute;
			width: 100%;
			background: map-get($theme-colors, 'blue');
			height: 4px;
			border-radius: 4px;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;

			&:nth-child(1) {
				top: 3px;
				transform-origin: left center;
			}

			&:nth-child(2) {
				top: 13px;
				transform-origin: left center;
			}

			&:nth-child(3) {
				top: 23px;
				transform-origin: left center;
			}
		}
	}

	.nav-link {
		&.active {
			@extend .text-orange;
		}
	}
}

.offcanvas {
	&.offcanvas-start {
		width: 75%;
		max-width: 400px;
	}

	&.offcanvas-responsive-lg {
		@include media-breakpoint-up(lg) {
			visibility: visible !important;
			position: static;
			width: 100%;
			border-right: none;
			transform: none;
			transition: none;
		}

		.offcanvas-header {
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}

		.offcanvas-body {
			@include media-breakpoint-up(lg) {
				padding: 0;
			}
		}

		+ .btn {
			@include media-breakpoint-up(lg) {
				display: none !important;
			}
		}
	}

	+ .btn {
		width: 60px;
		height: 60px;
		z-index: 900;
	}

	a {
		&.active {
			@extend .text-orange;
		}
	}
}

.infinity {
	position: relative;
	overflow-y: auto;

	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 48px;
		background: linear-gradient(
			0deg,
			rgba(250, 250, 250, 1) 0%,
			rgba(250, 250, 250, 0) 100%
		);
		bottom: 24px;
		left: 0;
	}

	&.infinity-blue-lightest {
		&::after {
			background: linear-gradient(
				0deg,
				rgba(map-get($theme-colors, 'blue-lightest'), 1) 0%,
				rgba(map-get($theme-colors, 'blue-lightest'), 0) 100%
			);
		}
	}
}

.table {
	thead,
	tbody {
		tr {
			th,
			td {
				padding: 8px;
			}
		}
	}
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
	--bs-table-accent-bg: #fafafa;
}

.sliderItemHalfAdmin,
.sliderItemFullAdmin {
	height: 320px !important;
}

.sliderItemHalf,
.sliderItemFull {
	position: relative;
	height: 420px;

	@include media-breakpoint-down(xl) {
		height: 340px;
	}

	img {
		padding: 0 !important;
		margin: 0 !important;
	}
}

.sliderItemFull {
	background: #ffffff;

	img {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		object-fit: cover;

		@include media-breakpoint-down(xl) {
			object-fit: fill;
		}
	}
}

/*

d1 96
d2 88
d3 72
d4 56

h1 40
h2 32
h3 28
h4 24
h5 20
h6 16

p lead 20
p 16
small 14

@include media-breakpoint-only(xs) { }
@include media-breakpoint-only(sm) { }
@include media-breakpoint-only(md) { }
@include media-breakpoint-only(lg) { }
@include media-breakpoint-only(xl) { }
@include media-breakpoint-only(xxl) { }

@include media-breakpoint-down(md) { }
@include media-breakpoint-down(lg) { }
@include media-breakpoint-down(xl) { }
@include media-breakpoint-down(xxl) { }

@include media-breakpoint-up(sm) { }
@include media-breakpoint-up(md) { }
@include media-breakpoint-up(lg) { }
@include media-breakpoint-up(xl) { }
@include media-breakpoint-up(xxl) { }

*/

.choice-item {
	position: relative;

	button {
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: -10px;
		z-index: 999;
		background: transparent;
		border: 0;
	}
}

.text-info {
	font-size: 13px;
	line-height: 21px;
	color: #212529;
}

.text-left {
	text-align: left;
}

/* Üniversity Page */
.university-page {
	.wrapper-university-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0;
		margin-bottom: 1rem;

		h3 {
			font-size: 20px;
			margin: 0;
			padding: 0;
		}

		button {
			max-width: 110px;
		}
	}

	.wrapper-university-content {
		height: 50vh;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: #f1f1f1 !important;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		user-select: none;

		li {
			list-style: none;
			margin: 0;
			padding: 1rem;
			cursor: pointer;
			overflow: hidden;

			&:last-child {
				border-bottom: 0;
			}

			&:hover,
			&.active {
				background-color: rgb(21, 101, 192) !important;

				> a {
					color: #fff !important;
				}

				div > a.action {
					display: inline;
				}
			}

			> a {
				float: left;
				text-decoration: none;
				max-width: 80%;
				min-width: 80%;

				@media screen and (max-width: 1399px) {
					max-width: 67%;
					min-width: 67%;
				}

				@media screen and (max-width: 767px) {
					max-width: 80%;
					min-width: 80%;
				}
			}

			> div {
				width: 50px;
				float: right;
			}

			> div > a.action {
				display: none;
				color: #fff !important;
			}
		}
	}
}

.btn-support {
	position: absolute;
	top: 0;
	right: 0;

	@media screen and (max-width: 575px) {
		position: static;
	}
}

.custom-slider {
	overflow-x: hidden;
}

.custom-slider input[type='checkbox'] {
	height: 0;
	width: 0;
	visibility: hidden;
}

.custom-slider label {
	cursor: pointer;
	text-indent: -9999px;
	width: 60px;
	height: 30px;
	background: grey;
	display: block;
	border-radius: 80px;
	position: relative;
	margin-top: -24px;
}

.custom-slider label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 20px;
	transition: 0.3s;
}

.custom-slider input:checked + label {
	background: #4caf50;
}

.custom-slider input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.custom-slider label:active:after {
	width: 130px;
}
